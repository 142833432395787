<template>
  <l-tela titulo="Cadastro de Entregadores">
    <l-form :salvar="salvar">
      <i-basico
        ref="inputNomeEntregador"
        id="nomeEntregador"
        label="Nome"
        v-model="form.nome"
        :validations="{ required: true, min: 3 }"
      />
      <i-contato
        id="contato"
        label="Contato"
        v-model="form.contato"
        :validations="{ required: true, validaLenCel: true }"
      />

      <v-data-table
        :headers="tabelaValores.colunas"
        :items="tabelaValores.registros"
        :disable-items-per-page="true"
        :footer-props="{
          itemsPerPageOptions: [],
        }"
      >
        <template v-slot:[`item.preco`]="{ item }">
          {{ item.valor | currency }}
        </template>

      </v-data-table>

      <template v-slot:botoes>
        <v-btn @click="sair">Sair</v-btn>
        <v-btn color="primary" type="submit">Salvar</v-btn>
      </template>
    </l-form>
  </l-tela>
</template>

<script>
import entregadorService from '@/services/entregador.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  data() {
    return {
      form: {
        _id: null,
        nome: '',
        contato: '',
      },
      tabelaValores: {
        colunas: [
          {
            text: 'Mês',
            value: 'mes',
            width: 400,
          },
          { text: 'Qtd. Entregas', value: 'quantidade' },
          { text: 'Valor', value: 'valor' },
        ],
        registros: [],
      },
    }
  },
  mounted() {
    const { entregadorId } = this.$route.params
    if (entregadorId) {
      this.carregar(entregadorId)
    } else {
      this.$refs.inputNomeEntregador.definirFoco()
    }
  },
  methods: {
    async carregar(entregadorId) {
      this.mostrarLoading()
      try {
        const { _id, nome, contato } = await entregadorService.buscaPorId(
          entregadorId
        )
        this.form = { _id, nome, contato }
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    async salvar() {
      this.mostrarLoading()
      try {
        const { _id, nome, contato } = this.form
        if (_id) {
          await entregadorService.atualizar({ _id, nome, contato })
        } else {
          await entregadorService.cadastrar({ nome, contato })
          this.sair()
        }
        this.alertaDadosSalvos()
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    sair() {
      this.$router.push({ name: 'rota-entregadores' })
    },
  },
}
</script>
